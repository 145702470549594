@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

.css-95f9g5-MuiToolbar-root {
  padding-left: 15px !important;
}

.css-15cv2x7-MuiInputBase-root-MuiInput-root::after {
  border-bottom: none!important;
}

.css-15cv2x7-MuiInputBase-root-MuiInput-root::before {
  border-bottom: none!important;
}

/* Switch material ui */
.css-j204z7-MuiFormControlLabel-root {
  border: 1px solid #B6BA3A;
  border-radius: 4px;
  margin-left: 0px!important;
}
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color:#B6BA3A!important;
}
.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #404040;
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  margin-right: 10px;
}
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track  {
  background-color: #B6BA3A!important;
}
/* fim do switch material ui */

/* Multi Select */

.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border-color: #E3E7FF!important;
  /* height: 28px!important; */
  padding: 6px 8px!important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #E3E7FF!important;
}

.css-1g9bazu-MuiButtonBase-root-MuiChip-root {
  border-radius: 4px!important; 
  border: 1px solid #B6BA3A!important;
  background-color: #FCFDF8!important;
}

.css-1g9bazu-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    color: #D42A2A!important;
}

.css-1g9bazu-MuiButtonBase-root-MuiChip-root {
  color: #404040;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px 8px!important;
}

.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  border-radius: 4px!important;
  border: 1px solid #E3E7FF!important;
  background: #FFF!important;
  height: 30px!important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
  color: black!important;
}

.modal {
  @apply fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg max-w-lg w-full;
}

.overlay {
  @apply fixed inset-0 bg-black bg-opacity-75;
}

/* Estilo para o contêiner com a barra de rolagem */
.scroll-container {
  max-height: 100vh;
  overflow-y: auto;
}

/* Estilo para a barra de rolagem */
.scroll-container::-webkit-scrollbar {
  width: 12px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #8A97B1;
}

/* toast */
.toast-custom-success {
  background-color: #008040 !important; /* Define o fundo verde */
  color: white !important; /* Para garantir que o texto seja legível */
}

.css-1ig8i4z-MuiToolbar-root {
  display: flex;
  justify-content: center;
}

/* Select do filtro da tela de Canais */
.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select  {
  height: 8px!important;
  min-height: 8px!important;

}

.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  border: 1px solid!important;
}

.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  border-color: #E3E7FF!important;

}
/* .css-yi3mkw {
  margin-top: -10px!important;
} */

.css-16yb58i-MuiButtonBase-root-MuiChip-root {
  border-radius: 4px!important;
  border: 1px solid #B6BA3A!important;
  background: #FCFDF8!important;
}
.css-16yb58i-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon  {
  color: #D42A2A!important;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: 9999;
}

.toast.success {
  background-color: green;
}

.toast.error {
  background-color: red;
}

.toast.show {
  opacity: 1;
}

.css-lcqmqn {
  height: 42px!important;
}

.css-1uxl2y1 {
  margin-left: 0px!important;
  width: 50px!important;

}

.css-pcrmjj {
    margin-top: 4px!important;
    width: 10px!important;
    height: 10px!important;
    background-color: white!important;
  }

.css-17z6nsr.Mui-checked + .MuiSwitch-track {
    background-color: #B6BA3A!important;
  }

.css-1mam9t9 {
  border-radius: 4px !important;
  border: 1px solid #B6BA3A !important;
  background: #FCFDF8 !important;
}

.css-1mam9t9 .MuiChip-deleteIcon {
  color: #D42A2A!important;
}

.css-fyswvn {
  font-weight: bold!important;
}

.css-yi3mkw {
  flex-wrap: nowrap!important;
}